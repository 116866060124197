import React from "react";
import MainWrapper from "../Main/MainWrapper";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import Form from "../Common/Form";
import { getCookie } from "../../utils";
import axios from "axios";

const EditManager = () => {
  const loginedUser = JSON.parse(getCookie("loginData"));
  const navigate = useNavigate();

  const [managers, setManagers] = useState([]);
  const [dealers, setDealers] = useState([]);
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  // Fetch managers and dealers from API on component mount
  const fetchManagersAndDealers = async () => {
    try {
      const managerResponse = await axios.get(
        "https://api.menuverse.ai/managers/show",
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${loginedUser.data.token}`,
          },
        }
      );

      const dealerResponse = await axios.get(
        "https://api.menuverse.ai/dealers/show",
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${loginedUser.data.token}`,
          },
        }
      );

      setManagers(
        managerResponse.data.data.map((manager) => ({
          name: manager.name,
          id: manager.id,
        }))
      );
      setDealers(
        dealerResponse.data.data.map((dealer) => ({
          name: dealer.name,
          id: dealer.id,
        }))
      );
    } catch (error) {
      console.error("Failed to fetch managers or dealers:", error);
    }
  };

  const fetchcities = async (state_id) => {
    console.log("fetch_cities called", state_id);
    try {
      const dealerResponse = await axios.post(
        "https://api.menuverse.ai/cities/showbystate",
        {
          state: state_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${loginedUser.data.token}`,
          },
        }
      );

      setCities(
        dealerResponse.data.data.map((dealer) => ({
          name: dealer.name,
          id: dealer.id,
        }))
      );
    } catch (error) {
      console.error("Failed to fetch managers or dealers:", error);
    }
  };

  const fetchstates = async () => {
    try {
      const dealerResponse = await axios.get(
        "https://api.menuverse.ai/states/show",
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${loginedUser.data.token}`,
          },
        }
      );

      setStates(
        dealerResponse.data.data.map((dealer) => ({
          name: dealer.name,
          id: dealer.id,
        }))
      );
    } catch (error) {
      console.error("Failed to fetch managers or dealers:", error);
    }
  };
  useEffect(() => {
    fetchManagersAndDealers();
    // fetchcities();
    fetchstates();
  }, []);

  // const cities = [
  //   { name: "Amritsar", id: 1 },
  //   { name: "Jalandhar", id: 2 },
  //   { name: "Ludhiana", id: 3 },
  // ];
  // const states = [
  //   { name: "Delhi", id: 1 },
  //   { name: "Punjab", id: 2 },
  //   { name: "Haryana", id: 3 },
  // ];

  const { id } = useParams();
  const [formStructure, setFormStructure] = useState([]);
  useEffect(() => {
    const formFields = [
      {
        fieldName: "firm_name",
        fieldType: "text",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldRequired: "true",
        fieldDefaultValue: "",
        fieldLabel: "Name",
        fieldPlaceholder: "Name",
      },
      {
        fieldName: "email",
        fieldType: "text",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldRequired: "true",
        fieldDefaultValue: "",
        fieldLabel: "Email Address",
        fieldPlaceholder: "Email Address",
      },
      {
        fieldName: "password",
        fieldType: "password",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldRequired: "true",
        fieldDefaultValue: "",
        fieldLabel: "Password",
        fieldPlaceholder: "Password",
      },
      {
        fieldName: "dba",
        fieldType: "hidden",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldDefaultValue: "",
        fieldPlaceholder: "DBA",
      },
      {
        fieldName: "tax_id",
        fieldType: "hidden",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldDefaultValue: "",
        fieldPlaceholder: "TAX ID",
      },
      {
        fieldName: "zip",
        fieldType: "text",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldRequired: "true",
        fieldDefaultValue: "",
        fieldLabel: "Zip Code",
        fieldPlaceholder: "Zip Code",
      },
      {
        fieldName: "phone",
        fieldType: "text",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldRequired: "true",
        fieldDefaultValue: "",
        fieldLabel: "Owner's Contact Number",
        fieldPlaceholder: "Phone-Number",
      },
      {
        fieldName: "fl_number",
        fieldType: "hidden",
        fieldMask: "",
        fieldMaxLength: "100",

        fieldDefaultValue: "",
        fieldPlaceholder: "FL-Number",
      },
      // {
      //   fieldName: "agreement_effective_date",
      //   fieldType: "date",
      //   fieldMask: "",
      //   fieldMaxLength: "100",
      //   fieldRequired: "true",
      //   fieldDefaultValue: "",
      //   fieldLabel: "Agreement Date",
      // },
      // {
      //   fieldName: "kick_off_date",
      //   fieldType: "date",
      //   fieldMask: "",
      //   fieldMaxLength: "100",
      //   fieldRequired: "true",
      //   fieldDefaultValue: "",
      //   fieldLabel: "Kick Off Date",
      // },
      {
        fieldName: "dealerId",
        fieldType: "select",
        fieldOptions: dealers,
        fieldLabel: "Dealer",
        fieldPlaceholder: "Select Dealer",
      },
      {
        fieldName: "state",
        fieldType: "select",
        fieldOptions: states,
        fieldLabel: "State",
        onChange: true,
        api_type: "post",
        api_url: "https://api.menuverse.ai/cities/showbystate",
        target_id: "city",
        fieldPlaceholder: "Select State",
      },
      {
        fieldName: "city",
        fieldType: "select",
        fieldMask: "",
        fieldMaxLength: "",
        fieldRequired: "true",
        fieldDefaultValue: "",
        fieldValue: "",
        fieldOptions: cities,
        // dbType: "integer",
        fieldLabel: "City",
        fieldId: "city",
        is_target: true,
      },

      // {
      //   fieldName: "state",
      //   fieldType: "select",
      //   fieldOptions: states,
      //   fieldLabel: "State",
      //   onChange: true,
      //   api_type: "post",
      //   api_url: "https://api.menuverse.ai/cities/showbystate",
      //   target_id: "city",
      // },
      {
        fieldName: "address",
        fieldType: "textarea",
        fieldMask: "",
        fieldMaxLength: "",
        fieldRequired: "true",
        fieldDefaultValue: "",
        fieldValue: "",
        fieldLabel: "Address",
        fieldPlaceholder: "Address",
      },
      {
        fieldName: "signature",
        fieldType: "signature",
        fieldRequired: "true",
        fieldLabel: "Signature",
        fieldPlaceholder: "Please sign here",
      },
    ];

    const getData = async () => {
      try {
        let record = await fetch(
          `https://api.menuverse.ai/managers/show/${id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${loginedUser.data.token}`,
            },
          }
        );

        if (record.status === 401 || record.status === 403) {
          navigate("/login");
          return;
        }

        if (record.status !== 200 && record.status !== 201) {
          const result = await record.json();
          window.toastr.error(result.message || "Error fetching data.");
          return;
        }

        record = await record.json();
        const dealerData = record.data;

        // Update formFields with default values and preserve fieldOptions for select fields
        const updatedFormStructure = formFields.map((field) => {
          const defaultValue =
            dealerData[field.fieldName] ?? field.fieldDefaultValue;

          // Preserve fieldOptions for select fields
          if (field.fieldType === "select") {
            const fieldOptions =
              field.fieldName === "dealerId"
                ? dealers
                : field.fieldName === "city"
                ? cities
                : field.fieldName === "state"
                ? states
                : field.fieldOptions || [];
            return { ...field, fieldDefaultValue: defaultValue, fieldOptions };
          }

          // For non-select fields, just update the default value
          return { ...field, fieldDefaultValue: defaultValue };
        });

        setFormStructure(updatedFormStructure);
      } catch (error) {
        console.error("Error fetching manager data:", error);
        window.toastr.error("Error fetching manager data.");
      }
    };

    getData();
  }, [managers, dealers, states]);

  const formDefinition = {
    type: "edit",
    URL: "https://api.menuverse.ai/managers/update/" + id,
    successRedirect: "/managers",
  };

  return (
    <>
      <MainWrapper title="Edit manager">
        <div className="card card-default" bis_skin_checked="1">
          <div className="card-header" bis_skin_checked="1">
            <h2></h2>
          </div>
          <div className="card-body" bis_skin_checked="1">
            <div
              className="row"
              bis_skin_checked="1"
              style={{
                border: "1px solid #eee",
                paddingTop: "20px",
                paddingBottom: "20px",
              }}
            >
              <div className="col-xl-12">
                <Form formFields={formStructure} {...formDefinition} />
              </div>
            </div>
          </div>
        </div>
      </MainWrapper>
    </>
  );
};

export default EditManager;
