import React, { useState, useEffect } from "react";
import MainWrapper from "../Main/MainWrapper";
import { useNavigate } from "react-router-dom";
import Form from "../Common/Form";
import axios from "axios";
import { getCookie, setCookie } from "../../utils";

const AddSalesperson = () => {
  const loginedUser = JSON.parse(getCookie("loginData"));
  const navigate = useNavigate();

  const [managers, setManagers] = useState([]);
  const [dealers, setDealers] = useState([]);
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState(""); // State for selected state

  useEffect(() => {
    const fetchManagersAndDealers = async () => {
      try {
        const managerResponse = await axios.get(
          "https://api.menuverse.ai/managers/show",
          {
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${loginedUser.data.token}`,
            },
          }
        );

        const dealerResponse = await axios.get(
          "https://api.menuverse.ai/dealers/show",
          {
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${loginedUser.data.token}`,
            },
          }
        );

        setManagers(
          managerResponse.data.data.map((manager) => ({
            name: manager.name,
            id: manager.id,
          }))
        );
        setDealers(
          dealerResponse.data.data.map((dealer) => ({
            name: dealer.name,
            id: dealer.id,
          }))
        );
      } catch (error) {
        console.error("Failed to fetch managers or dealers:", error);
      }
    };

    const fetchStates = async () => {
      try {
        const statesResponse = await axios.get(
          "https://api.menuverse.ai/states/show",
          {
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${loginedUser.data.token}`,
            },
          }
        );

        setStates(
          statesResponse.data.data.map((state) => ({
            name: state.name,
            id: state.id,
          }))
        );
      } catch (error) {
        console.error("Failed to fetch states:", error);
      }
    };

    fetchManagersAndDealers();
    fetchStates();
  }, [loginedUser.data.token]);

  // Fetch cities based on the selected state

  const formFields = [
    {
      fieldName: "firm_name",
      fieldType: "text",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldLabel: "Name",
      fieldPlaceholder: "Name",
    },
    {
      fieldName: "email",
      fieldType: "text",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldLabel: "Email Address",
      fieldPlaceholder: "Email Address",
    },
    {
      fieldName: "password",
      fieldType: "password",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldLabel: "Password",
      fieldPlaceholder: "Password",
    },
    {
      fieldName: "dba",
      fieldType: "hidden",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldDefaultValue: "",
      fieldPlaceholder: "DBA",
    },
    {
      fieldName: "tax_id",
      fieldType: "hidden",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldDefaultValue: "",
      fieldPlaceholder: "TAX Id",
    },
    {
      fieldName: "zip",
      fieldType: "text",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldLabel: "Zip Code",
      fieldPlaceholder: "Zip Code",
    },
    {
      fieldName: "phone",
      fieldType: "text",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldLabel: "Owner's Contact Number",
      fieldPlaceholder: "Phone-Number",
    },
    {
      fieldName: "fl_number",
      fieldType: "hidden",
      fieldMask: "",
      fieldMaxLength: "100",

      fieldDefaultValue: "",
      fieldPlaceholder: "FL-Number",
    },
    // {
    //   fieldName: "agreement_effective_date",
    //   fieldType: "date",
    //   fieldMask: "",
    //   fieldMaxLength: "100",
    //   fieldRequired: "true",
    //   fieldDefaultValue: "",
    //   fieldLabel: "Agreement Date",
    // },
    // {
    //   fieldName: "kick_off_date",
    //   fieldType: "date",
    //   fieldMask: "",
    //   fieldMaxLength: "100",
    //   fieldRequired: "true",
    //   fieldDefaultValue: "",
    //   fieldLabel: "Kick Off Date",
    // },
    {
      fieldName: "managerId",
      fieldType: "select",
      fieldOptions: managers,
      fieldLabel: "Manager",
      fieldPlaceholder: "Select Manager",
    },
    {
      fieldName: "dealerId",
      fieldType: "select",
      fieldOptions: dealers,
      fieldLabel: "Dealer",
      fieldPlaceholder: "Select Dealer",
    },
    {
      fieldName: "state",
      fieldType: "select",
      fieldOptions: states,
      fieldLabel: "State",
      onChange: true,
      api_type: "post",
      api_url: "https://api.menuverse.ai/cities/showbystate",
      target_id: "city",
      fieldPlaceholder: "Select State",
    },
    {
      fieldName: "city",
      fieldType: "select",
      fieldMask: "",
      fieldMaxLength: "",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldValue: "",
      fieldOptions: cities,
      // dbType: "integer",
      fieldLabel: "City",
      fieldId: "city",
      is_target: true,
    },

    {
      fieldName: "address",
      fieldType: "textarea",
      fieldMask: "",
      fieldMaxLength: "",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldValue: "",
      fieldLabel: "Address",
      fieldPlaceholder: "Address",
    },
    {
      fieldName: "signature",
      fieldType: "signature",
      fieldRequired: "true",
      fieldLabel: "Signature",
      fieldPlaceholder: "Please sign here",
    },
  ];

  const formDefinition = {
    type: "add",
    URL: "https://api.menuverse.ai/salespersons/create",
    successRedirect: "/salespersons",
  };

  return (
    <>
      <MainWrapper title="Add Sales person">
        <div className="card card-default" bis_skin_checked="1">
          <div className="card-header" bis_skin_checked="1">
            <h2></h2>
          </div>
          <div className="card-body" bis_skin_checked="1">
            <div
              className="row"
              bis_skin_checked="1"
              style={{
                border: "1px solid #eee",
                paddingTop: "20px",
                paddingBottom: "20px",
              }}
            >
              <div className="col-xl-12">
                <Form formFields={formFields} {...formDefinition} />
              </div>
            </div>
          </div>
        </div>
      </MainWrapper>
    </>
  );
};

export default AddSalesperson;
