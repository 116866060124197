import React, { forwardRef } from "react";
import { useState } from "react";
import { useEffect } from "react";

const Textarea = forwardRef(
  (
    {
      fieldName,
      fieldType,
      fieldMask,
      fieldMaxLength,
      fieldRequired,
      fieldValue,
      formDataObj,
      value,
      fieldOptions,
      onData,
      validate,
      fieldLabel,
      fieldPlaceholder,
      dynamicValues,
    },
    ref
  ) => {
    fieldValue = fieldValue ? fieldValue : formDataObj[fieldName];
    const [fieldNewValue, setFieldValue] = useState(fieldValue);

    useEffect(() => {
      setFieldValue(fieldValue);
    }, [fieldValue]);
    const handleChange = (e) => {
      if (fieldRequired == "true") {
        validateField(e.target.value, e);
      }
      setFieldValue(e.target.value);
      formDataObj = { ...formDataObj, [fieldName]: e.target.value };
      onData(formDataObj);
      console.log(formDataObj);
    };

    const handleDyanmicValues = (e) => {
      alert("hello");
      e.preventDefault();

      // Ensure the target is a TEXTAREA element
      const clickedElement = e.target;
      if (clickedElement.tagName !== "TEXTAREA") {
        console.error("Event target is not a TEXTAREA element.");
        return;
      }

      let ulElement = clickedElement.nextElementSibling;

      if (ulElement && ulElement.tagName === "UL") {
        ulElement.style.display = "block";
      }
    };

    const handleApplyDynamicValue = (e) => {
      const clickedLi = e.target;
      const clickedLiValue = clickedLi.getAttribute("d_value");

      // alert(clickedLiValue);

      const liParentDiv = clickedLi.closest("div");

      if (liParentDiv && liParentDiv.tagName == "DIV") {
        alert("yes");
        const textareaEl = liParentDiv.querySelector("textarea");

        const cursorPosition = textareaEl.selectionStart;

        if (cursorPosition !== undefined && textareaEl) {
          const inputValue = textareaEl.value;

          const newValue =
            inputValue.slice(0, cursorPosition) +
            clickedLiValue +
            inputValue.slice(cursorPosition);

          textareaEl.value = newValue;

          setFieldValue(newValue);

          onData({
            ...formDataObj,
            [fieldName]: newValue,
          });

          // Trigger the input event to notify React or any other listener
          textareaEl.dispatchEvent(new Event("input", { bubbles: true }));

          // Calculate new cursor position
          const newCursorPosition = cursorPosition + clickedLiValue.length;

          // Focus the textarea element and set the cursor position
          textareaEl.focus();
          textareaEl.setSelectionRange(newCursorPosition, newCursorPosition);
        } else {
          // If cursorPosition is undefined, focus on the textarea and place the cursor at the end
          textareaEl.focus();
          const endPosition = textareaEl.value.length;
          textareaEl.setSelectionRange(endPosition, endPosition);
        }
      }
    };

    const validateField = (givenValue, el) => {
      console.log("my parent", el.target.parentElement);
      const myParent = el.target.parentElement;
      if (fieldType === "textarea") {
        if (givenValue == "") {
          el.target.style.borderColor = "red";
          myParent.querySelector("span").textContent = "This field is required";
          myParent.querySelector("span").style.display = "block";
          validate(false);
        } else {
          el.target.style.borderColor = "#e5e9f2";
          myParent.querySelector("span").textContent = "This field is required";
          myParent.querySelector("span").style.display = "none";
          validate(true);
        }
      }
    };

    const validateFieldEmpty = (el) => {
      const myParent = el.target.parentElement;
      if (fieldType === "textarea") {
        if (el.target.value == "" && fieldRequired == "true") {
          el.target.style.borderColor = "red";
          myParent.querySelector("span").textContent = "This field is required";
          myParent.querySelector("span").style.display = "block";
        }
      }
    };
    return (
      <div ref={ref} style={{ width: "100%" }}>
        <textarea
          className="form-control"
          name={fieldName}
          onChange={handleChange}
          value={fieldNewValue}
          placeholder={fieldPlaceholder}
          data-isrequired={fieldRequired == "true" ? "true" : "false"}
          onContextMenu={handleDyanmicValues}
          onBlur={validateFieldEmpty}
        ></textarea>
        <ul style={{ display: "none" }} className="dynamicValues">
          {dynamicValues &&
            dynamicValues.map((d_value) => (
              <li
                key={d_value.id}
                onClick={handleApplyDynamicValue}
                d_value={d_value.id}
              >
                {d_value.name}
              </li>
            ))}
        </ul>
        <div style={{ display: "block", width: "100%" }}>
          <span style={{ color: "red", display: "none" }}>
            This field is required
          </span>
        </div>
      </div>
    );
  }
);

export default Textarea;
