import React, { useState } from "react";
import MainWrapper from "../Main/MainWrapper";
import { useNavigate } from "react-router-dom";
import { setCookie } from "../../utils";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";

function Login() {
  const navigate = useNavigate();
  const [form, setForm] = useState({ email: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e) => {
    e.preventDefault();
    const name = e.target.name;

    console.log("name", name);
    const value = e.target.value;
    setForm({ ...form, [name]: value });

    console.log("form", form);
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = form;

    console.log("Submit Data: ", data);

    try {
      const response = await fetch("https://api.menuverse.ai/users/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (response.status === 201 || response.status === 200) {
        const result = await response.json();

        if (
          result.data.roleId != 1 &&
          result.data.roleId != 2 &&
          result.data.roleId != 3 &&
          result.data.roleId != 4
        ) {
          toast.error("Incorrect Details");
        } else if (result.data.roleId == 2) {
          delete result.data.signature;
          delete result.data.dealer_sign;
          setCookie("loginData", JSON.stringify(result), 1);
          console.log("resp: ", result);
          navigate("/");
          window.location.reload();
        } else {
          delete result.data.signature;
          delete result.data.dealer_sign;
          setCookie("loginData", JSON.stringify(result), 1);
          console.log("resp: ", result);
          navigate("/");
          window.location.reload();
        }
      } else if (response.status === 500 || response.status === 401) {
        const result = await response.json();
        console.log("resp: ", result);
        toast.error("Incorrect Details");
      }
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };
  return (
    <>
      <div
        className="container d-flex align-items-center justify-content-center"
        style={{ minHeight: "100vh" }}
      >
        <ToastContainer />
        <div className="d-flex flex-column justify-content-between">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-10">
              <div className="card card-default mb-0">
                <div className="card-header pb-0">
                  <div className="app-brand w-100 d-flex justify-content-center border-bottom-0">
                    <a className="w-auto pl-0" href="/index.html">
                      <img src="images/logo2.png" alt="Mono" />
                    </a>
                  </div>
                </div>
                <div className="card-body px-5 pb-5 pt-0">
                  <h4 className="text-dark mb-6 text-center">Sign in</h4>

                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="form-group col-md-12 mb-4">
                        <input
                          type="email"
                          className="form-control input-lg"
                          id="email"
                          name="email"
                          onChange={handleChange}
                          value={form.email}
                          aria-describedby="emailHelp"
                          placeholder="email"
                        />
                      </div>
                      <div className="form-group col-md-12">
                        <div className="input-group">
                          <input
                            type={showPassword ? "text" : "password"}
                            className="form-control input-lg"
                            id="password"
                            name="password"
                            onChange={handleChange}
                            value={form.password}
                            placeholder="Password"
                          />
                          <div className="input-group-append">
                            <button
                              type="button"
                              className="btn btn-outline-secondary"
                              onClick={togglePasswordVisibility}
                              style={{
                                border: "1px solid #ced4da",
                                borderRadius: "0 .25rem .25rem 0",
                              }}
                            >
                              {showPassword ? <FaEyeSlash /> : <FaEye />}
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="d-flex justify-content-between mb-3">
                          <div className="custom-control custom-checkbox mr-3 mb-3">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="customCheck2"
                            />
                            <label
                              className="custom-control-label"
                              for="customCheck2"
                            >
                              Remember me
                            </label>
                          </div>

                          <a className="text-color" href="#">
                            {" "}
                            Forgot password?{" "}
                          </a>
                        </div>

                        <button
                          type="submit"
                          className="btn btn-primary btn-pill mb-4"
                        >
                          Sign In
                        </button>

                        <p></p>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
