import React, { useEffect, useState } from "react";
import { getCookie } from "../../utils";
import socket from "../Common/Socket";
import MainWrapper from "./MainWrapper";
import { FaBell, FaRegBell } from "react-icons/fa";
import "./Notification.css";

const AdminNotifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);

  useEffect(() => {
    const user = JSON.parse(getCookie("loginData"));
    const roleId = user.data.roleId;
  
    if (!roleId) {
      console.error("Role ID not found in cookies");
      return;
    }
  
    // Emit event to fetch notifications
    socket.emit("getadminNotifications", { roleId });
  
    // Listen for admin notifications
    const handleNotifications = (response) => {
      if (response && response.notifications) {
        setNotifications(response.notifications);
        setUnreadCount(response.unreadCount);
        console.log("Received admin notifications:", response);
      }
    };
  
    const handleNewNotification = (newNotification) => {
      setNotifications((prev) => [newNotification, ...prev]);
      setUnreadCount((prevUnreadCount) => prevUnreadCount + 1);
      console.log("Received new notification:", newNotification);
    };
  
    socket.on("adminnotifications", handleNotifications);
    socket.on("addNotification", handleNewNotification);
  
    // Clean up
    return () => {
      socket.off("adminnotifications", handleNotifications);
      socket.off("addNotification", handleNewNotification);
    };
  }, []);
  

if(socket.connect){
  socket.on("adminnotifications", (response) => {
    if (response && response.notifications) {
      // Instead of replacing all notifications, update only the unread ones or new notifications.
      setNotifications(response.notifications);
      setUnreadCount(response.unreadCount);
      console.log("Received admin notifications111:", response);
    }
  });
}


  const markAsRead = () => {
    const user = JSON.parse(getCookie("loginData"));
    const roleId = user?.data?.roleId;
  
    // Optimistically update the UI first
    setUnreadCount(0);
    setNotifications((prev) =>
      prev.map((notif) => ({ ...notif, is_read: 1 }))
    );
  
    // Emit the event to mark notifications as read on the server
    socket.emit("markadminAsRead", { roleId }, (response) => {
      if (response?.success) {
        console.log("Notifications marked as read on the server.");
      } else {
        console.error("Error marking notifications as read:", response?.message);
        // Revert UI changes if the server-side update fails
        socket.emit("getadminNotifications", { roleId }); // Re-fetch notifications
      }
    });
  };
  


  return (
    <div className="notifications-container">
      <MainWrapper title="Admin Notifications">
        <div className="header">
          <h2>Admin Notifications</h2>
          <div className="notification-icon">
            {unreadCount > 0 ? (
              <FaBell className="bell-icon" />
            ) : (
              <FaRegBell className="bell-icon" />
            )}
            <span className="badge">{unreadCount}</span>
          </div>

          <button
            className="mark-read-button"
            onClick={markAsRead}
            disabled={unreadCount === 0}
          >
            Mark All as Read
          </button>
        </div>
        <div className="notification-list">
          {notifications.length > 0 ? (
            notifications.map((notification) => (
              <div
                key={notification.id}
                className={`notification-item ${notification.is_read === 0 ? "unread" : ""}`}
              >
                <div className="notification-content">
                  <h4>{notification.title}</h4>
                  <p>{notification.message}</p>
                </div>
              </div>
            ))
          ) : (
            <p>No notifications available.</p>
          )}
        </div>
      </MainWrapper>
    </div>
  );
};

export default AdminNotifications;
