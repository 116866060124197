import React from "react";
import { useState } from "react";
import Input from "../Common/Input";
import Select from "../Common/Select";
import Textarea from "../Common/Textarea";
import parse from "html-react-parser";

const MultipleFormSections = ({
  multiples,
  multipleName,
  formDataObj,
  onMultipleData,
  onComponentClone,
  sectionIndex,
  formValues,
  showDefaultRadio,
  onSelectOption,
  optionTitle,
  validate,
  onDelete,
  index,
}) => {
  const ref = React.createRef(); // Example ref
  let prevFieldName = null;
  const [formData, setFormData] = useState(formDataObj);

  const handleChange = (e) => {
    onMultipleData({ ...formDataObj, [multipleName]: e });
  };

  const handleClone = () => {
    onComponentClone();
  };

  const handleDelete = () => {
    onDelete();
  };

  const handleShowDefaults = (e) => {
    // onSelectOption(e);
  };

  const handleValidations = (e) => {
    validate(e);
  };

  return (
    <div
      ref={ref}
      className="col-xl-6"
      style={{
        background: "#eee",
        marginBottom: "15px",
        borderRight: "1px solid #ddd",
        padding: "10px 15px",
      }}
    >
      <h3> {optionTitle ? optionTitle : "Answer Option"} #</h3>;
      <div className="row">
        {multiples.map((multipleColumn, index) => {
          let newMulipleInput = "";
          if (
            multipleColumn.fieldType == "text" ||
            multipleColumn.fieldType == "radio" ||
            multipleColumn.fieldType == "number" ||
            multipleColumn.fieldType == "date"
          ) {
            newMulipleInput =
              prevFieldName === multipleColumn.fieldName ? (
                <div className="col-xl-12" bis_skin_checked="1">
                  <div
                    className="mb-5"
                    style={{ marginTop: "-45px" }}
                    bis_skin_checked="1"
                  >
                    <label
                      className="text-dark font-weight-medium"
                      for=""
                    ></label>
                    <div className="input-group mb-3" bis_skin_checked="1">
                      <Input
                        sectionIndex={sectionIndex}
                        {...multipleColumn}
                        defaultValue={multipleColumn.fieldDefaultValue}
                        multiple={multipleName}
                        multiples={formDataObj[multipleName]}
                        formDataObj={formDataObj}
                        formValues={formValues}
                        onData={handleChange}
                        showDefaultRadio={showDefaultRadio}
                        validate={handleValidations}
                      />
                    </div>
                    <p style={{ fontSize: "90%" }}></p>
                  </div>
                </div>
              ) : (
                <div
                  className="col-xl-12"
                  bis_skin_checked="1"
                  style={
                    multipleColumn.fieldName == "id" ? { display: "none" } : {}
                  }
                >
                  <div className="mb-5" bis_skin_checked="1">
                    <label className="text-dark font-weight-medium" for="">
                      {multipleColumn.fieldLabel
                        ? parse(multipleColumn.fieldLabel)
                        : multipleColumn.fieldName.replace(/_/g, " ")}
                    </label>
                    <div className="input-group mb-3" bis_skin_checked="1">
                      <Input
                        sectionIndex={sectionIndex}
                        {...multipleColumn}
                        multiple={multipleName}
                        multiples={formDataObj[multipleName]}
                        formDataObj={formData}
                        onData={handleChange}
                        formValues={formValues}
                        showDefaultRadio={showDefaultRadio}
                        validate={handleValidations}
                      />
                    </div>
                    <p style={{ fontSize: "90%" }}></p>
                  </div>
                </div>
              );

            prevFieldName = multipleColumn.fieldName;
          } else if (multipleColumn.fieldType == "checkbox") {
            newMulipleInput = (
              <div
                className="col-xl-12"
                bis_skin_checked="1"
                style={
                  multipleColumn.fieldName == "id" ? { display: "none" } : {}
                }
              >
                <div className="mb-5" bis_skin_checked="1">
                  <div className="input-group mb-3" bis_skin_checked="1">
                    <Input
                      sectionIndex={sectionIndex}
                      {...multipleColumn}
                      multiple={multipleName}
                      multiples={formDataObj[multipleName]}
                      formDataObj={formData}
                      onData={handleChange}
                      formValues={formValues}
                      showDefaultRadio={showDefaultRadio}
                      validate={handleValidations}
                    />
                    <span style={{ marginLeft: "10px" }}>
                      {multipleColumn.fieldLabel
                        ? parse(multipleColumn.fieldLabel)
                        : multipleColumn.fieldName.replace(/_/g, " ")}
                    </span>
                  </div>
                  <p style={{ fontSize: "90%" }}></p>
                </div>
              </div>
            );
          } else if (multipleColumn.fieldType == "select") {
            newMulipleInput = (
              <div className="col-xl-12" bis_skin_checked="1">
                <div className="mb-5" bis_skin_checked="1">
                  <label className="text-dark font-weight-medium" for="">
                    {multipleColumn.fieldLabel
                      ? parse(multipleColumn.fieldLabel)
                      : multipleColumn.fieldName.replace(/_/g, " ")}
                  </label>
                  <div className="input-group mb-3" bis_skin_checked="1">
                    <Select
                      sectionIndex={sectionIndex}
                      {...multipleColumn}
                      multiple={multipleName}
                      multiples={formDataObj[multipleName]}
                      onData={handleChange}
                      formDataObj={formData}
                      select_multiple={
                        multipleColumn.select_multiple ? "true" : "false"
                      }
                      formValues={formValues}
                      onChange={() => {}}
                      onSelectOption={handleShowDefaults}
                      validate={handleValidations}
                    />
                  </div>
                  <p style={{ fontSize: "90%" }}></p>
                </div>
              </div>
            );
          } else if (multipleColumn.fieldType == "textarea") {
            newMulipleInput = (
              <div className="col-xl-12" bis_skin_checked="1">
                <div className="mb-5" bis_skin_checked="1">
                  <label className="text-dark font-weight-medium" for="">
                    {multipleColumn.fieldLabel
                      ? parse(multipleColumn.fieldLabel)
                      : multipleColumn.fieldName.replace(/_/g, " ")}
                  </label>
                  <div className="input-group mb-3" bis_skin_checked="1">
                    <Textarea
                      sectionIndex={sectionIndex}
                      {...multipleColumn}
                      multiple={multipleName}
                      multiples={formDataObj[multipleName]}
                      formDataObj={formData}
                      onData={handleChange}
                      formValues={formValues}
                      validate={handleValidations}
                    />
                  </div>
                  <p style={{ fontSize: "90%" }}></p>
                </div>
              </div>
            );
          }
          return newMulipleInput;
        })}
      </div>
      <button
        type="button"
        className="btn btn-sm btn-warning float-right"
        onClick={handleClone}
      >
        + Option
      </button>
      <button
        type="button"
        className="btn btn-sm btn-danger float-right mr-2"
        onClick={handleDelete} // Attach delete handler
      >
        - Option
      </button>
    </div>
  );
};

export default MultipleFormSections;
