import React from "react";
import MainWrapper from "../Main/MainWrapper";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import Form from "../Common/Form";
import { getCookie } from "../../utils";

const EditQuestion = () => {
  const loginedUser = JSON.parse(getCookie("loginData"));
  const navigate = useNavigate();
  const [dbData, setDbData] = useState("");
  const cities = [
    { name: "Amritsar", id: 1 },
    { name: "Jalandhar", id: 2 },
    { name: "Ludhiana", id: 3 },
  ];
  const states = [
    { name: "Delhi", id: 1 },
    { name: "Punjab", id: 2 },
    { name: "Haryana", id: 3 },
  ];

  const dynamicValues = [
    { name: "Name", id: "%name%" },
    { name: "City", id: "%city%" },
    { name: "State", id: "%state%" },
    { name: "Car Miles", id: "%miles%" },
    { name: "Trading", id: "%trading%" },
  ];

  const types = [
    { name: "Question", id: "Question" },
    { name: "Game", id: "Game" },
  ];
  const questionTypes = [
    { name: "Checkbox", id: "checkbox" },
    { name: "Slider", id: "slider" },
    { name: "Number", id: "number" },
  ];
  const { id } = useParams();
  const [services, setSevices] = useState([]);
  useEffect(() => {
    const fetchService = async () => {
      const response = await fetch(
        "https://api.menuverse.ai/service_category/show",
        {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + loginedUser.data.token,
          },
        }
      );

      if (response.status === 201 || response.status === 200) {
      } else if (response.status === 500) {
        const result = await response.json();
        window.toastr.error(result.message);
      } else if (response.status === 401 || response.status === 403) {
        navigate("/login");
      }

      const responseData = await response.json();

      setSevices(responseData.data);

      console.log("Services Data", responseData);
    };
    fetchService();
  }, []);

  const [formStructure, setFormStructure] = useState([]);
  useEffect(() => {
    const formFields = [
      {
        fieldName: "title",
        fieldType: "textarea",
        fieldMask: "",
        fieldMaxLength: "225",
        fieldRequired: "true",
        fieldDefaultValue: "",
        fieldLabel: "Question",
      },
      {
        fieldName: "title_spanish",
        fieldType: "textarea",
        fieldMask: "",
        fieldMaxLength: "225",
        fieldRequired: "true",
        fieldDefaultValue: "",
        fieldLabel: "Question (Spanish)",
      },
      {
        fieldName: "type",
        fieldType: "select",
        fieldMask: "",
        fieldMaxLength: "",
        fieldRequired: "true",
        fieldDefaultValue: "",
        fieldValue: "",
        fieldOptions: types,
        dbType: "string",
        fieldLabel: "Next slide would be question or a game?",
      },
      {
        fieldName: "questionType",
        fieldType: "select",
        fieldMask: "",
        fieldMaxLength: "",
        fieldRequired: "true",
        fieldDefaultValue: "",
        fieldValue: "",
        fieldOptions: questionTypes,
        dbType: "string",
        fieldLabel: "Answer Type",
      },
      {
        fieldName: "jackpot",
        fieldType: "hidden",
        fieldMask: "",
        fieldMaxLength: "100",

        fieldDefaultValue: "",
        fieldLabel:
          "Any Jackpot connected to this question?  <em style='color: #333; font-size: 14px; font-weight: 400'>(Optional)</em>",
      },
      {
        fieldName: "jackpotType",
        fieldType: "hidden",
        fieldMask: "",
        fieldMaxLength: "100",

        fieldDefaultValue: "",
        fieldLabel:
          "Type of Jackpot?  <em style='color: #333; font-size: 14px; font-weight: 400'>(Optional)</em>",
      },
      {
        fieldName: "video",
        fieldType: "text",
        fieldMask: "",
        fieldMaxLength: "100",

        fieldDefaultValue: "",
        fieldLabel:
          "Video URL  <em style='color: #333; font-size: 14px; font-weight: 400'>(Optional)</em>",
      },

      {
        fieldName: "video_spanish",
        fieldType: "text",
        fieldMask: "",
        fieldMaxLength: "100",

        fieldDefaultValue: "",
        fieldLabel:
          "Video URL  <em style='color: #333; font-size: 14px; font-weight: 400'>(Optional)</em>(Spanish)",
      },

      {
        fieldName: "questionOptions",
        fieldType: "multiples",
        fieldMask: "",
        fieldMaxLength: "100",
        fieldRequired: "true",
        fieldDefaultValue: "",
        multiples: [
          {
            fieldName: "id",
            fieldType: "text",
            fieldMask: "",
            fieldMaxLength: "100",
            fieldRequired: "true",
            fieldDefaultValue: "",
            fieldLabel: "id",
          },
          {
            fieldName: "title",
            fieldType: "text",
            fieldMask: "",
            fieldMaxLength: "100",
            fieldRequired: "true",
            fieldDefaultValue: "",
            fieldLabel: "Answer Title/Text",
          },
          {
            fieldName: "title_spanish",
            fieldType: "text",
            fieldMask: "",
            fieldMaxLength: "100",
            fieldRequired: "true",
            fieldDefaultValue: "",
            fieldLabel: "Answer Title/Text (Spanish)",
          },
          {
            fieldName: "range",
            fieldType: "text",
            fieldMask: "",
            fieldMaxLength: "100",

            fieldDefaultValue: "",
            fieldLabel:
              "Range If Any <em style='color: #333; font-size: 14px; font-weight: 400'>(This is mostly used in slider type of questions)</em>",
          },
          {
            fieldName: "threshold",
            fieldType: "number",
            fieldMask: "",
            fieldMaxLength: "100",

            fieldDefaultValue: "",
            fieldLabel:
              "Threshold <em style='color: #333; font-size: 14px; font-weight: 400'>(This is used to refer service)</em>",
          },
          {
            fieldName: "increment_value",
            fieldType: "text",
            fieldMask: "",
            fieldMaxLength: "100",

            fieldDefaultValue: "",
            fieldLabel:
              "Increment Value <em style='color: #333; font-size: 14px; font-weight: 400'>(This is used if you question is of number type)</em>",
          },
          {
            fieldName: "default",
            fieldType: "radio",
            fieldMask: "",
            fieldMaxLength: "100",
            fieldRequired: "false",
            fieldDefaultValue: "No",
            fieldValue: "Yes",
            fieldLabel:
              "Set as Default <em style='color: #333; font-size: 14px; font-weight: 400'>(Set default selected option if your question of checkbox or slider type)</em>",
          },
          {
            fieldName: "default",
            fieldType: "radio",
            fieldMask: "",
            fieldMaxLength: "100",
            fieldRequired: "false",
            fieldDefaultValue: "No",
            fieldValue: "No",
            fieldLabel: "Set as Default",
          },
          {
            fieldName: "default_value",
            fieldType: "text",
            fieldMask: "",
            fieldMaxLength: "100",

            fieldDefaultValue: "",
            fieldLabel:
              "Default value <em style='color: #333; font-size: 14px; font-weight: 400'>(Set default number what should be starting point if your question of number type)</em>",
          },
          {
            fieldName: "externalurl",
            fieldType: "text",
            fieldMask: "",
            fieldMaxLength: "100",

            fieldDefaultValue: "",
            fieldLabel:
              "External URL If Any <em style='color: #333; font-size: 14px; font-weight: 400'>(If you want to take user to any other site)</em>",
          },
          {
            fieldName: "service_id",
            fieldType: "select",
            fieldMask: "",
            fieldMaxLength: "",
            fieldRequired: "true",
            fieldDefaultValue: "",
            fieldValue: "",
            fieldOptions: services,
            select_multiple: "true",
            dbType: "integer",
            fieldLabel:
              "Service <em style='color: #333; font-size: 14px; font-weight: 400'>(Select the service which should be selected as recommended according to this answer)</em>",
          },
        ],
      },
    ];

    const getData = async () => {
      let record = await fetch(
        "https://api.menuverse.ai/questions/showdetail/" + id,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + loginedUser.data.token,
          },
        }
      );

      if (record.status === 201 || record.status === 200) {
      } else if (record.status === 500) {
        const result = await record.json();
        window.toastr.error(result.message);
      } else if (record.status === 401 || record.status === 403) {
        navigate("/login");
      }

      record = await record.json();
      const dealerData = record.data;

      setDbData(dealerData);

      console.log("dealerData: ", dealerData);

      setFormStructure(
        formFields.map((field) => {
          return { ...field, fieldDefaultValue: dealerData[field.fieldName] };
        })
      );
    };
    getData();
  }, [services]);

  const formDefinition = {
    type: "edit",
    URL: "https://api.menuverse.ai/questions/update/" + id,
    successRedirect: "/questions",
  };

  return (
    <>
      <MainWrapper title="Edit Question">
        <div className="card card-default" bis_skin_checked="1">
          <div className="card-header" bis_skin_checked="1">
            <h2></h2>
          </div>
          <div className="card-body" bis_skin_checked="1">
            <div
              className="row"
              bis_skin_checked="1"
              style={{
                border: "1px solid #eee",
                paddingTop: "20px",
                paddingBottom: "20px",
              }}
            >
              <div className="col-xl-12">
                <Form
                  formFields={formStructure}
                  {...formDefinition}
                  dbFormData={dbData}
                />
              </div>
            </div>
          </div>
        </div>
      </MainWrapper>
    </>
  );
};

export default EditQuestion;
