import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useState } from "react";
import { getCookie, setCookie } from "../../utils";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();
  const loginedUser = JSON.parse(getCookie("loginData"));
  // console.log("pageUrl: ", pageUrl);

  useEffect(() => {
    const cookie = getCookie("loginData");
    if (!cookie) {
      navigate("/login"); // Redirect to login page if the cookie is not found
    }
  }, [navigate]);

  const location = useLocation();
  const pageUrl = location.pathname.split("/")[1];

  const [activeMenu, setActiveMenu] = useState(pageUrl);

  const menus = [];
  if (loginedUser) {
    if (loginedUser.data.perimissions.dashboard.includes("create")) {
      menus.push({
        href: "/",
        title: "Dashboard",
        iconClass: "mdi mdi-briefcase-account-outline",
        activeUrls: [""],
      });
    }

    if (loginedUser.data.perimissions.dealers.includes("create")) {
      menus.push({
        href: "/dealers",
        title: "Dealers",
        iconClass: "mdi mdi-handshake-outline",
        activeUrls: ["dealers", "add-dealer", "edit-dealer"],
      });
    }

    if (loginedUser.data.perimissions.managers.includes("create")) {
      menus.push({
        href: "/managers",
        title: "managers",
        iconClass: "mdi mdi-handshake-outline",
        activeUrls: ["managers", "add-manager", "edit-manager"],
      });
    }

    if (loginedUser.data.perimissions.salespersons.includes("create")) {
      menus.push({
        href: "/salespersons",
        title: "salespersons",
        iconClass: "mdi mdi-handshake-outline",
        activeUrls: ["salespersons", "add-salesperson", "edit-salesperson"],
      });
    }

    if (loginedUser.data.perimissions.states.includes("create")) {
      menus.push({
        href: "/states",
        title: "States",
        iconClass: "mdi mdi-map",
        activeUrls: ["states", "add-state", "edit-state"],
      });
    }

    if (loginedUser.data.perimissions.reports.includes("create")) {
      menus.push({
        href: "/reports",
        title: "Reports",
        iconClass: "mdi mdi-map",
        activeUrls: ["reports"],
      });
    }

    if (loginedUser.data.perimissions.comparisonreports.includes("create")) {
      menus.push({
        href: "/Comparisonreports",
        title: "Comparison Reports",
        iconClass: "mdi mdi-map",
        activeUrls: ["Comparisonreports"],
      });
    }

    if (loginedUser.data.perimissions.cities.includes("create")) {
      menus.push({
        href: "/cities",
        title: "Cities",
        iconClass: "mdi mdi-city",
        activeUrls: ["cities", "add-city", "edit-city"],
      });
    }

    if (loginedUser.data.perimissions.refer.includes("show")) {
      menus.push({
        href: "/refer",
        title: "Refer",
        iconClass: "mdi mdi-city",
        activeUrls: ["refer"],
      });
    }

    if (loginedUser.data.perimissions.service_category.includes("create")) {
      menus.push({
        href: "/service-categories",
        title: "Product Categories",
        iconClass: "mdi mdi-shape-outline",
        activeUrls: [
          "service-categories",
          "add-service-category",
          "edit-service-category",
        ],
      });
    }

    if (loginedUser.data.perimissions.service_product_type.includes("create")) {
      menus.push({
        href: "/service-product-type",
        title: "Product Types",
        iconClass: "mdi mdi-package-variant-closed",
        activeUrls: [
          "service-product-type",
          "add-service-product-type",
          "edit-service-product-type",
        ],
      });
    }

    if (loginedUser.data.perimissions.questions.includes("create")) {
      menus.push({
        href: "/questions",
        title: "Questions",
        iconClass: "mdi mdi-forum-plus-outline",
        activeUrls: ["questions", "add-question", "edit-question"],
      });
    }

    if (loginedUser.data.perimissions.vendor.includes("create")) {
      menus.push({
        href: "/vendors",
        title: "Vendors",
        iconClass: "mdi mdi-store",
        activeUrls: ["vendors", "add-vendor", "edit-vendor"],
      });
    }

    if (loginedUser.data.perimissions.newdeal.includes("create")) {
      menus.push({
        href: "/newdeals",
        title: "Deals",
        iconClass: "mdi mdi-account-filter-outline",
        activeUrls: ["newdeals", "add-newdeal", "edit-newdeal"],
      });
    }

    if (loginedUser.data.perimissions.salestool.includes("create")) {
      menus.push({
        href: "/salestools",
        title: "SaleTools",
        iconClass: "mdi mdi-toolbox-outline",
        activeUrls: ["salestools", "add-saletool", "edit-saletool"],
      });
    }

    if (loginedUser.data.perimissions.dmscode.includes("create")) {
      menus.push({
        href: "/dmscodes",
        title: "DMS Code",
        iconClass: "mdi mdi-list-box-outline",
        activeUrls: ["dmscodes", "add-dmscode", "edit-dmscode"],
      });
    }

    if (loginedUser.data.perimissions.coupons.includes("create")) {
      menus.push({
        href: "/coupons",
        title: "Discount Coupons",
        iconClass: "mdi mdi-list-box-outline",
        activeUrls: ["coupons", "add-coupon", "edit-coupon"],
      });
    }

    if (loginedUser.data.perimissions.partner.includes("create")) {
      menus.push({
        href: "/partners",
        title: "Partner",
        iconClass: "mdi mdi-account-group-outline",
        activeUrls: ["partners", "add-partner", "edit-partner"],
      });
    }

    if (loginedUser.data.perimissions.product.includes("create")) {
      menus.push({
        href: "/products",
        title: "Products",
        iconClass: "mdi mdi-package-variant-closed",
        activeUrls: ["products", "add-product", "edit-product"],
      });
    }

    if (loginedUser.data.perimissions.users.includes("create")) {
      menus.push({
        href: "/users",
        title: "Users",
        iconClass: "mdi mdi-package-variant-closed",
        activeUrls: ["users"],
      });
    }

    if (loginedUser.data.perimissions.review.includes("create")) {
      menus.push({
        href: "/reviews",
        title: "Reviews",
        iconClass: "mdi mdi-package-variant-closed",
        activeUrls: ["reviews"],
      });
    }

    if (loginedUser.data.perimissions.sign.includes("show")) {
      menus.push({
        href: "/deal_contracts",
        title: "Deal contracts",
        iconClass: "mdi mdi-package-variant-closed",
        activeUrls: ["deal_contracts"],
      });
    }


    if(loginedUser.data.roleId == 1){
    if (loginedUser.data.perimissions.customers.includes("create")) {
      menus.push({
        href: "/customers",
        title: "customers",
        iconClass: "mdi mdi-shape-outline",
        activeUrls: ["customers"],
      });
    }
      
    }
  }

  useEffect(() => {
    setActiveMenu(pageUrl);
  }, []);
  return (
    <>
      <div id="toaster"></div>
      <aside className="left-sidebar sidebar-dark" id="left-sidebar">
        <div id="sidebar" className="sidebar sidebar-with-footer">
          <div className="app-brand">
            <a>
              <img src="/images/logo2.png" alt="Mono" />
            </a>
          </div>
          <div
            className="sidebar-left"
            data-simplebar
            style={{ height: "100%" }}
          >
            <ul className="nav sidebar-inner" id="sidebar-menu">
              {/* <li className="section-title">Apps</li> */}
              {menus.map((menu, index) => {
                return (
                  <li
                    className={
                      menu.activeUrls.includes(activeMenu) ? "active" : ""
                    }
                  >
                    <Link to={menu.href} className="sidenav-item-link">
                      <i className={menu.iconClass}></i>
                      <span className="nav-text">{menu.title}</span>
                    </Link>
                  </li>
                );
              })}

             
            </ul>
          </div>

          <div className="sidebar-footer">
            <div className="sidebar-footer-content">
              <hr></hr>
            </div>
          </div>
        </div>
      </aside>
    </>
  );
};

export default Navbar;
