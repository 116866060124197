import React, { useEffect, useState } from "react";
import { getCookie } from "../../utils";
import socket from "../Common/Socket";
import MainWrapper from "./MainWrapper";
import { FaBell, FaRegBell } from "react-icons/fa";
import "./Notification.css";

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);

  useEffect(() => {
    const user = JSON.parse(getCookie("loginData"));
    const roleId = user.data.roleId;
    const userId = user.data.id;

    if (!roleId || !userId) {
      console.error("Role ID or User ID not found in cookies");
      return;
    }

    // Emit event to fetch notifications for dealers on page load or navigation
    socket.emit("getNotifications", { userId });

    // Set up socket listeners for real-time updates (for dealer notifications)
    socket.on("notifications", (response) => {
      if (response) {
        setNotifications(response.notifications);
        setUnreadCount(response.unreadCount);
        console.log("Received dealer notifications:", response);
      }
    });

    socket.on("error", (message) => {
      console.error("Socket error:", message);
    });

    // Clean up listeners on unmount
    return () => {
      socket.off("notifications");
      socket.off("error");
    };
  }, []); // This runs on every navigation to the page


  const markAsRead = () => {
    const user = JSON.parse(getCookie("loginData"));
    const userId = user?.data?.id;
  
    // Optimistically update the UI
    setUnreadCount(0); // Reset unread count immediately
    setNotifications((prev) =>
      prev.map((notif) => ({ ...notif, is_read: 1 })) // Mark all notifications as read
    );
  
    // Emit the event to the server
    socket.emit("markNotificationAsRead", { userId }, (response) => {
      if (response?.success) {
        console.log("Notifications marked as read.");
      } else {
        console.error("Error marking notifications as read:", response?.message);
  
        // Optionally, if the server response indicates failure, revert the UI change
        // (This is just an example; you could handle this differently based on your use case)
        setUnreadCount(prevCount => prevCount); // Restore previous unread count
        setNotifications(prev =>
          prev.map((notif) => ({ ...notif, is_read: 0 })) // Revert notifications back to unread
        );
      }
    });
  };
  

  return (
    <div className="notifications-container">
      <MainWrapper title="Notifications">
        <div className="header">
          <h2>Notifications</h2>
          <div className="notification-icon">
            {unreadCount > 0 ? (
              <FaBell className="bell-icon" />
            ) : (
              <FaRegBell className="bell-icon" />
            )}
            <span className="badge">{unreadCount}</span>
          </div>
          <div>
            <button
              className="mark-read-button"
              onClick={markAsRead}
              disabled={unreadCount === 0}
            >
              Mark All as Read
            </button>
          </div>
        </div>
        <div className="notification-list">
          {notifications.length > 0 ? (
            notifications.map((notification) => (
              <div
                key={notification.id}
                className={`notification-item ${notification.is_read === 0 ? "unread" : ""}`}
              >
                <div className="notification-content">
                  <h4>{notification.title}</h4>
                  <p>{notification.message}</p>
                </div>
              </div>
            ))
          ) : (
            <p>No notifications available.</p>
          )}
        </div>
      </MainWrapper>
    </div>
  );
};

export default Notifications;
