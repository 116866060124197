import React, { useState, forwardRef, useRef, useEffect } from "react";
import SignatureCanvas from "react-signature-canvas";

const Input = forwardRef(
  (
    {
      fieldName,
      fieldType,
      fieldMask,
      fieldMaxLength,
      fieldRequired,
      fieldValue,
      formDataObj,
      fieldDefaultValue,
      dbType,
      onData,
      multiple,
      multiples,
      fieldLabel,
      sectionIndex,
      fieldDbValue,
      formValues,
      dynamicValues,
      validate,
      showDefaultRadio,
      fieldPlaceholder,
    },
    ref
  ) => {
    // console.log("defaultValue", formValues);
    const [fieldNewValue, setFieldValue] = useState(fieldDefaultValue);

    const [newMultiples, setNewMultiples] = useState(multiples);

    const [mainVal, setMainVal] = useState("");

    const [visible, setVisisble] = useState(false);

    const validationRules = {
      maxLength: "Max length cannot be greater than " + fieldMaxLength,
    };

    const [signatureData, setSignatureData] = useState(null);
    const [isSignatureEmpty, setIsSignatureEmpty] = useState(true);
    const sigCanvasRef = useRef(null);

    useEffect(() => {
      if (fieldType === "signature" && fieldDefaultValue) {
        console.log("SIGNATURE CAME", fieldDefaultValue);

        const resizeBase64Image = (base64, width, height, callback) => {
          const img = new Image();
          img.onload = () => {
            // Create an offscreen canvas for resizing
            const offscreenCanvas = document.createElement("canvas");
            offscreenCanvas.width = width;
            offscreenCanvas.height = height;

            const ctx = offscreenCanvas.getContext("2d");
            ctx.drawImage(img, 0, 0, width, height);

            // Convert the resized image back to base64
            const resizedBase64 = offscreenCanvas.toDataURL("image/png");
            callback(resizedBase64);
          };
          img.src = base64;
        };

        const canvas = sigCanvasRef.current;

        // Resize the base64 image to match the canvas dimensions
        resizeBase64Image(fieldDefaultValue, 500, 200, (resizedBase64) => {
          canvas.fromDataURL(resizedBase64);
        });
      }
    }, [fieldType, fieldDefaultValue]);

    useEffect(() => {
      setFieldValue(
        multiple ? formValues[sectionIndex][fieldName] : fieldDefaultValue
      );
    }, [fieldDefaultValue]);

    const handleSignatureClear = () => {
      sigCanvasRef.current.clear();
      setSignatureData(null);
      setIsSignatureEmpty(true);
      onData({
        ...formDataObj,
        [fieldName]: null, // Reset the signature field in form data
      });
    };

    const handleSignatureSave = () => {
      if (sigCanvasRef.current.isEmpty()) {
        alert("Please draw a signature.");
        return;
      }
      const signatureImage = sigCanvasRef.current
        .getTrimmedCanvas()
        .toDataURL("image/png");
      setSignatureData(signatureImage);
      setIsSignatureEmpty(false);
      onData({
        ...formDataObj,
        [fieldName]: signatureImage, // Save the signature to form data
      });

      console.log("Saved signature data:", signatureImage);
    };

    ///////////////////////// phone mask //////////////////

    useEffect(() => {
      setFieldValue(
        multiple ? formValues[sectionIndex][fieldName] : fieldDefaultValue
      );
    }, [fieldDefaultValue]);
    // console.log(fieldDefaultValue);

    const handleDyanmicValues = (e) => {
      alert("heelo");
      e.preventDefault();
      // Ensure the target is an LI element
      const clickedElement = e.target;
      if (clickedElement.tagName !== "INPUT") {
        console.error("Event target is not an INPUT element.");
        return;
      }

      let ulElement = clickedElement.nextElementSibling;

      if (ulElement && ulElement.tagName == "UL") {
        ulElement.style.display = "block";
      }
    };

    document.addEventListener("click", function (event) {
      const ul = document.querySelectorAll(".dynamicValues");

      ul.forEach(function (v) {
        v.style.display = "none";
      });
    });

    const handleApplyDynamicValue = (e) => {
      const clickedLi = e.target;
      const clickedLiValue = clickedLi.getAttribute("d_value");

      // alert(clickedLiValue);

      const liParentDiv = clickedLi.closest("div");

      if (liParentDiv && liParentDiv.tagName == "DIV") {
        const inputEl = liParentDiv.querySelector("input");

        const cursorPosition = inputEl.selectionStart;

        if (cursorPosition) {
          const inputValue = inputEl.value;

          const newValue =
            inputValue.slice(0, cursorPosition) +
            clickedLiValue +
            inputValue.slice(cursorPosition);

          inputEl.value = newValue;

          setFieldValue(newValue);

          onData({
            ...formDataObj,
            [fieldName]: newValue,
          });
          inputEl.dispatchEvent(new Event("input", { bubbles: true }));

          const newCursorPosition = cursorPosition + clickedLiValue.length;
          inputEl.setSelectionRange(newCursorPosition, newCursorPosition);
        }
      }
    };

    const handleChange = (e) => {
      const inputType = e.target.getAttribute("type");
      const inputname = e.target.getAttribute("name");
      const { id, value, type, files, signature } = e.target;

      fieldDefaultValue = e.target.value;
      const hasMultiple = e.target.getAttribute("hasmultiple");
      const targetName = e.target.getAttribute("name");
      const targetIndex = e.target.getAttribute("sectionIndex");

      if (hasMultiple != "") {
        const input = e.target;

        if (input.getAttribute("type") == "radio") {
          const radioName = input.getAttribute("name");
          const label = input.parentElement;
          const row = input.closest(".row");
          const radioButtons = row.querySelectorAll(".radio-button");
          // alert(radioName);
          // console.log(radioButtons);
          const allRadios = row.querySelectorAll(
            'input[name="' + radioName + '"]'
          );

          allRadios.forEach(function (radio) {
            // console.log("ALL RADIOOOOSSSSS", radio);
            radio.nextSibling.classList.remove("active");
          });

          label.querySelector("span").classList.add("active");
        } else {
          setFieldValue(e.target.value);
        }

        multiples = multiples.map((val, index) => {
          if (index == targetIndex) {
            if (inputType == "checkbox") {
              if (e.target.checked) {
                return { ...val, [targetName]: 1 };
              } else {
                return { ...val, [targetName]: 0 };
              }
            } else {
              return { ...val, [targetName]: e.target.value };
            }
          } else {
            return { ...val };
          }
        });

        onData(multiples);
      } else {
        if (inputname === "phone" || inputname === "phoneNumber") {
          let formattedValue = value.replace(/\D/g, ""); // Remove non-numeric characters

          if (formattedValue.length === 0) {
            // If the value is empty, reset the formatted value and the field
            setFieldValue("");
            onData({
              ...formDataObj,
              [fieldName]: "",
            });
            return; // Exit early
          }

          // Format the phone number to US format (XXX) XXX-XXXX
          if (formattedValue.length <= 3) {
            formattedValue = `(${formattedValue}`;
          } else if (formattedValue.length <= 6) {
            formattedValue = `(${formattedValue.slice(
              0,
              3
            )}) ${formattedValue.slice(3)}`;
          } else {
            formattedValue = `(${formattedValue.slice(
              0,
              3
            )}) ${formattedValue.slice(3, 6)}-${formattedValue.slice(6, 10)}`;
          }

          // Store the formatted value and the raw number (digits only)
          setFieldValue(formattedValue);
          onData({
            ...formDataObj,
            [fieldName]: formattedValue.replace(/\D/g, ""), // Save unformatted number (only digits)
          });
        } else if (inputType === "file") {
          console.log("FILE CHANGED");
          // Handle file input change
          onData({
            ...formDataObj,
            [fieldName]: files[0],
            signature,
          });
        } else if (inputType == "checkbox") {
          if (e.target.checked) {
            setFieldValue(1);
          } else {
            setFieldValue(0);
          }
        } else {
          setFieldValue(e.target.value);
          validateField(e.target.value, e);
          onData({
            ...formDataObj,
            [fieldName]: e.target.value,
          });
        }

        console.log("changes occured");
      }
    };

    const validateField = (givenValue, el) => {
      console.log("my parent", el.target.parentElement);
      const myParent = el.target.parentElement;
      if (fieldType === "text") {
        if (fieldMaxLength != "") {
          console.log("fieldMaxLength", fieldMaxLength);
          console.log("givenValue", givenValue);
          if (givenValue.length > fieldMaxLength) {
            console.log("value exceeded");
            el.target.style.borderColor = "red";
            myParent.querySelector("span").textContent =
              "Max length cannot be greater than " + fieldMaxLength;
            myParent.querySelector("span").style.display = "block";
            validate(false);
          } else {
            el.target.style.borderColor = "#e5e9f2";
            myParent.querySelector("span").style.display = "none";
            validate(true);
          }
        }
      }
    };

    const validateFieldEmpty = (el) => {
      const myParent = el.target.parentElement;
      if (fieldType === "text") {
        if (el.target.value == "" && fieldRequired == "true") {
          el.target.style.borderColor = "red";
          myParent.querySelector("span").textContent = "This field is required";
          myParent.querySelector("span").style.display = "block";
        }
      }
    };

    useEffect(() => {
      if (multiple) {
        setMainVal(formDataObj[multiple][sectionIndex][fieldName]);
      }
    }, [formDataObj]);

    // console.log("showDefaultRadio", showDefaultRadio);

    return (
      <>
        <div style={{ width: "100%" }} ref={ref}>
          {fieldType === "radio" ? (
            multiple ? (
              fieldType === "radio" ? (
                <ul>
                  <li style={{ marginRight: "15px" }}>
                    <label
                      htmlFor={fieldName}
                      style={{ position: "relative", zIndex: "1" }}
                    >
                      <input
                        sectionIndex={sectionIndex}
                        type={fieldType}
                        data-mask={fieldMask}
                        name={fieldName}
                        value={fieldValue}
                        autoComplete="off"
                        hasmultiple={multiple ? multiple : ""}
                        onChange={handleChange}
                        onBlur={validateFieldEmpty}
                        data-active={
                          multiple ? [mainVal] : formDataObj[fieldName]
                        }
                        data-isrequired={
                          fieldRequired === "true" ? "true" : "false"
                        }
                        checked={formDataObj[fieldName] === fieldValue}
                      />
                      <span
                        className={`radio-button ${
                          multiple
                            ? mainVal === fieldValue
                              ? "active"
                              : ""
                            : formDataObj[fieldName] === fieldValue
                            ? "active"
                            : ""
                        }`}
                      ></span>
                    </label>
                    <span
                      style={{
                        position: "absolute",
                        marginLeft: "10px",
                        top: "-3px",
                        zIndex: "0",
                      }}
                    >
                      {fieldValue}
                    </span>
                  </li>
                </ul>
              ) : (
                <ul>
                  <li style={{ marginRight: "15px" }}>
                    <label
                      htmlFor={fieldName}
                      style={{ position: "relative", zIndex: "1" }}
                    >
                      <input
                        sectionIndex={sectionIndex}
                        type={fieldType}
                        data-mask={fieldMask}
                        name={fieldName}
                        value={fieldNewValue}
                        autoComplete="off"
                        hasmultiple={multiple ? multiple : ""}
                        onChange={handleChange}
                        onBlur={validateFieldEmpty}
                        data-active={
                          multiple ? [mainVal] : formDataObj[fieldName]
                        }
                        data-isrequired={
                          fieldRequired === "true" ? "true" : "false"
                        }
                        checked={formDataObj[fieldName] === fieldValue}
                      />
                      <span
                        className={`radio-button ${
                          multiple
                            ? mainVal === fieldValue
                              ? "active"
                              : ""
                            : formDataObj[fieldName] === fieldValue
                            ? "active"
                            : ""
                        }`}
                      ></span>
                    </label>
                    <span
                      style={{
                        position: "absolute",
                        marginLeft: "10px",
                        top: "-3px",
                        zIndex: "0",
                      }}
                    >
                      {fieldValue}
                    </span>
                  </li>
                </ul>
              )
            ) : (
              <ul>
                <li style={{ marginRight: "15px" }}>
                  <label
                    htmlFor={fieldName}
                    style={{ position: "relative", zIndex: "1" }}
                  >
                    <input
                      sectionIndex={sectionIndex}
                      type={fieldType}
                      data-mask={fieldMask}
                      name={fieldName}
                      value={fieldValue}
                      min="0"
                      maxLength={
                        fieldType === "password"
                          ? 16
                          : fieldName === "zip"
                          ? 12
                          : null
                      }
                      autoComplete="off"
                      placeholder={fieldPlaceholder}
                      hasmultiple={multiple ? multiple : ""}
                      onChange={handleChange}
                      onBlur={validateFieldEmpty}
                      data-isrequired={
                        fieldRequired === "true" ? "true" : "false"
                      }
                      data-active={
                        multiple ? [mainVal] : formDataObj[fieldName]
                      }
                      checked={formDataObj[fieldName] === fieldValue}
                    />
                    <span
                      className={`radio-button ${
                        multiple
                          ? mainVal === fieldValue
                            ? "active"
                            : ""
                          : formDataObj[fieldName] === fieldValue
                          ? "active"
                          : ""
                      }`}
                    ></span>
                  </label>
                  <span
                    style={{
                      position: "absolute",
                      marginLeft: "10px",
                      top: "-3px",
                      zIndex: "0",
                    }}
                  >
                    {fieldValue}
                  </span>
                </li>
              </ul>
            )
          ) : fieldType === "checkbox" ? (
            <>
              <input
                sectionIndex={sectionIndex}
                type={fieldName !== "id" ? fieldType : "hidden"}
                data-mask={fieldMask}
                autoComplete="off"
                name={fieldName}
                value={fieldValue}
                maxLength={
                  fieldType === "password"
                    ? 16
                    : fieldName === "zip"
                    ? 12
                    : null
                }
                newvalue={fieldNewValue}
                hasmultiple={multiple ? multiple : ""}
                onChange={handleChange}
                onBlur={validateFieldEmpty}
                onContextMenu={handleDyanmicValues}
                data-isrequired={fieldRequired === "true" ? "true" : "false"}
                checked={fieldNewValue === fieldValue}
              />
              <ul style={{ display: "none" }} className="dynamicValues">
                {dynamicValues &&
                  dynamicValues.map((d_value) => (
                    <li
                      key={d_value.id}
                      onClick={handleApplyDynamicValue}
                      d_value={d_value.id}
                    >
                      {d_value.name}
                    </li>
                  ))}
              </ul>
            </>
          ) : fieldType === "signature" ? (
            <div>
              <SignatureCanvas
                ref={sigCanvasRef}
                penColor="black"
                name={fieldName}
                value={fieldValue}
                onChange={handleChange}
                canvasProps={{
                  width: 500,
                  height: 200,
                  className: "sigCanvas border border-2 border-dark",
                }}
              />
              <div style={{ marginTop: "10px" }}>
                <button
                  type="button"
                  className="btn btn-danger me-2"
                  onClick={handleSignatureClear}
                >
                  Clear
                </button>
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={handleSignatureSave}
                >
                  Save Signature
                </button>
              </div>
              {isSignatureEmpty && (
                <div style={{ color: "red" }}>
                  Please provide a signature to proceed.
                </div>
              )}
            </div>
          ) : fieldType === "file" ? (
            <>
              <input
                sectionIndex={sectionIndex}
                type={fieldName !== "id" ? fieldType : "hidden"}
                className="form-control"
                data-mask={fieldMask}
                autoComplete="off"
                name={fieldName}
                step="any"
                placeholder={fieldPlaceholder}
                data-isrequired={fieldRequired === "true" ? "true" : "false"}
                hasmultiple={multiple ? multiple : ""}
                onChange={handleChange}
                onBlur={validateFieldEmpty}
                onContextMenu={handleDyanmicValues}
              />
              <p>
                <img
                  src={"https://api.menuverse.ai/" + fieldDefaultValue}
                  alt="Uploaded Logo"
                  width="200px"
                />
              </p>
              <ul style={{ display: "none" }} className="dynamicValues">
                {dynamicValues &&
                  dynamicValues.map((d_value) => (
                    <li
                      key={d_value.id}
                      onClick={handleApplyDynamicValue}
                      d_value={d_value.id}
                    >
                      {d_value.name}
                    </li>
                  ))}
              </ul>
            </>
          ) : (
            <>
              <input
                sectionIndex={sectionIndex}
                type={fieldName !== "id" ? fieldType : "hidden"}
                className="form-control"
                data-mask={fieldMask}
                autoComplete="off"
                name={fieldName}
                value={fieldNewValue}
                min="0"
                step="any"
                maxLength={
                  fieldType === "password"
                    ? 16
                    : fieldName === "zip"
                    ? 12
                    : null
                }
                placeholder={fieldPlaceholder}
                data-isrequired={fieldRequired === "true" ? "true" : "false"}
                hasmultiple={multiple ? multiple : ""}
                onChange={handleChange}
                onBlur={validateFieldEmpty}
                onContextMenu={handleDyanmicValues}
              />
              <ul style={{ display: "none" }} className="dynamicValues">
                {dynamicValues &&
                  dynamicValues.map((d_value) => (
                    <li
                      key={d_value.id}
                      onClick={handleApplyDynamicValue}
                      d_value={d_value.id}
                    >
                      {d_value.name}
                    </li>
                  ))}
              </ul>
            </>
          )}
          <div style={{ display: "block", width: "100%" }}>
            <span style={{ color: "red", display: "none" }}>
              This field is required
            </span>
          </div>
        </div>
      </>
    );
  }
);

export default Input;
