import React from "react";
import MainWrapper from "../Main/MainWrapper";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Form from "../Common/Form";
import axios from "axios";
import { getCookie } from "../../utils";

const AddDealer = () => {
  const loginedUser = JSON.parse(getCookie("loginData"));
  const navigate = useNavigate();

  const [managers, setManagers] = useState([]);
  const [dealers, setDealers] = useState([]);
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  // Fetch managers and dealers from API on component mount
  useEffect(() => {
    const fetchManagersAndDealers = async () => {
      try {
        const managerResponse = await axios.get(
          "https://api.menuverse.ai/managers/show",
          {
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${loginedUser.data.token}`,
            },
          }
        );

        const dealerResponse = await axios.get(
          "https://api.menuverse.ai/dealers/show",
          {
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${loginedUser.data.token}`,
            },
          }
        );

        setManagers(
          managerResponse.data.data.map((manager) => ({
            name: manager.name,
            id: manager.id,
          }))
        );
        setDealers(
          dealerResponse.data.data.map((dealer) => ({
            name: dealer.name,
            id: dealer.id,
          }))
        );
      } catch (error) {
        console.error("Failed to fetch managers or dealers:", error);
      }
    };

    const fetchcities = async () => {
      try {
        const dealerResponse = await axios.get(
          "https://api.menuverse.ai/cities/show",
          {
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${loginedUser.data.token}`,
            },
          }
        );

        setCities(
          dealerResponse.data.data.map((dealer) => ({
            name: dealer.name,
            id: dealer.id,
          }))
        );
      } catch (error) {
        console.error("Failed to fetch managers or dealers:", error);
      }
    };

    const fetchstates = async () => {
      try {
        const dealerResponse = await axios.get(
          "https://api.menuverse.ai/states/show",
          {
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${loginedUser.data.token}`,
            },
          }
        );

        setStates(
          dealerResponse.data.data.map((dealer) => ({
            name: dealer.name,
            id: dealer.id,
          }))
        );
      } catch (error) {
        console.error("Failed to fetch managers or dealers:", error);
      }
    };

    fetchManagersAndDealers();
    // fetchcities();
    fetchstates();
  }, [loginedUser.data.token]);

  const formFields = [
    {
      fieldName: "firm_name",
      fieldType: "text",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldLabel: "Name of the Firm?",
      fieldPlaceholder: "Name of firm",
    },
    {
      fieldName: "email",
      fieldType: "text",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldLabel: "Email Address",
      fieldPlaceholder: "Email",
    },
    {
      fieldName: "logo",
      fieldType: "file",
      fieldRequired: "true",
      fieldLabel: "Upload Logo",
      fieldPlaceholder: "Upload logo",
    },
    {
      fieldName: "password",
      fieldType: "password",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldLabel: "Password",
      fieldPlaceholder: "Password",
    },
    {
      fieldName: "dba",
      fieldType: "hidden",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldDefaultValue: "",
      fieldPlaceholder: "DBA",
    },
    {
      fieldName: "tax_id",
      fieldType: "hidden",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldDefaultValue: "",
      fieldPlaceholder: "TAX ID",
    },
    {
      fieldName: "zip",
      fieldType: "text",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldLabel: "Zip Code",
      fieldPlaceholder: "Zip Code",
    },
    {
      fieldName: "phone",
      fieldType: "text",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldLabel: "Owner's Contact Number",
      fieldPlaceholder: "Phone-Number",
    },
    {
      fieldName: "fl_number",
      fieldType: "hidden",
      fieldMask: "",
      fieldMaxLength: "100",

      fieldDefaultValue: "",
      fieldPlaceholder: "FL-Number",
    },
    {
      fieldName: "agreement_effective_date",
      fieldType: "date",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldLabel: "Agreement Date",
      fieldPlaceholder: "Agreement Date",
    },
    {
      fieldName: "kick_off_date",
      fieldType: "date",
      fieldMask: "",
      fieldMaxLength: "100",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldLabel: "Kick Off Date",
      fieldPlaceholder: "Agreement Date",
    },
    {
      fieldName: "state",
      fieldType: "select",
      fieldOptions: states,
      fieldLabel: "State",
      onChange: true,
      api_type: "post",
      api_url: "https://api.menuverse.ai/cities/showbystate",
      target_id: "city",
      fieldPlaceholder: "Select state",
    },
    {
      fieldName: "city",
      fieldType: "select",
      fieldMask: "",
      fieldMaxLength: "",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldValue: "",
      fieldOptions: cities,
      // dbType: "integer",
      fieldLabel: "City",
      fieldId: "city",
      is_target: true,
    },

    // {
    //   fieldName: "state",
    //   fieldType: "select",
    //   fieldOptions: states,
    //   fieldLabel: "State",
    //   onChange: true,
    //   api_type: "post",
    //   api_url: "https://api.menuverse.ai/cities/showbystate",
    //   target_id: "city",
    // },
    {
      fieldName: "address",
      fieldType: "textarea",
      fieldMask: "",
      fieldMaxLength: "",
      fieldRequired: "true",
      fieldDefaultValue: "",
      fieldValue: "",
      fieldLabel: "Address",
      fieldPlaceholder: "Address",
    },
    {
      fieldName: "signature",
      fieldType: "signature",
      fieldRequired: "false",
      fieldLabel: "Signature",
      fieldPlaceholder: "Please sign here",
    },
  ];

  const formDefinition = {
    type: "add",
    URL: "https://api.menuverse.ai/dealers/create",
    successRedirect: "/dealers",
  };

  return (
    <>
      <MainWrapper title="Add Dealer">
        <div className="card card-default" bis_skin_checked="1">
          <div className="card-header" bis_skin_checked="1">
            <h2></h2>
          </div>
          <div className="card-body" bis_skin_checked="1">
            <div
              className="row"
              bis_skin_checked="1"
              style={{
                border: "1px solid #eee",
                paddingTop: "20px",
                paddingBottom: "20px",
              }}
            >
              <div className="col-xl-12">
                <Form formFields={formFields} {...formDefinition} />
              </div>
            </div>
          </div>
        </div>
      </MainWrapper>
    </>
  );
};

export default AddDealer;
